import { createStyles, makeStyles } from '@material-ui/core/styles'

const style = makeStyles((theme) =>
  createStyles({
    drawerPaper: {
      backgroundColor: theme.palette.primary.main,
      width: 250,
    },
  })
)

export default style
