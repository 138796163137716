import React, { memo, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { Box } from '@material-ui/core'

import InternalServerError from '~/components/Error/InternalServerError'
import NotFound from '~/components/Error/NotFound'
import ErrorUnauthorized from '~/components/Error/Unauthorized'
import Loading from '~/components/Loading'
import routes from '~/routes'

import Sidebar from '../Sidebar'

import useCustom from './hooks'

const ProtectedLayout = () => {
  const { data } = useCustom()

  return (
    <Box display="flex" height="100%">
      <Sidebar />

      <InternalServerError>
        <Suspense fallback={<Loading height="100%" loading />}>
          <Switch>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                component={route.component}
                exact={!!route.exact}
              />
            ))}
            <Route exact path="/">
              <Redirect to={data.defaultPath as any} />
            </Route>
            <Route path="/unauthorized" component={ErrorUnauthorized} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </InternalServerError>
    </Box>
  )
}

export default memo(ProtectedLayout)
