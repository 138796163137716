import React from 'react'
import { memo } from 'react'

import { AppBar, Box, IconButton, Toolbar } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import { colors } from '~/styles/colors'
import { fontSize } from '~/styles/theme'

import useStyle from './style'

interface Props {
  handleClickExpandMenu: () => void
}

const NavbarMobile = ({ handleClickExpandMenu }: Props) => {
  const classes = useStyle()

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar>
        <Box display="flex" alignItems="center">
          {/* Expand button */}
          <IconButton
            aria-label="burger-menu-btn"
            id="account-navbar-btn"
            onClick={handleClickExpandMenu}
          >
            <Box fontSize={fontSize[40]}>
              <MenuIcon htmlColor={colors.White} fontSize="inherit" />
            </Box>
          </IconButton>

          {/* Image logo */}
          <img
            className={classes.imgLogo}
            src="/public/brands/logo-fleeto-white.svg"
          />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default memo(NavbarMobile)
