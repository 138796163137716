import { makeStyles, Theme } from '@material-ui/core/styles'

import { currentSubdomain, SUBDOMAINS } from '~/config'
import { colors } from '~/styles/theme'

interface IProps {
  open: boolean
}

const drawerWidth = 220
const closedDrawerWidth = 60

// TODO: still for experimental

const style = makeStyles<Theme, IProps>((theme) => {
  const MAPPING_CSS_DOMAIN = {
    [SUBDOMAINS.FLEETO_MAIN_SUBDOMAIN]: theme.palette.primary.main,
    [SUBDOMAINS.FLEETO_ALVA_SUBDOMAIN]: theme.palette.primary.main,
  }
  return {
    logo: {
      height: '1.6vw',
      margin: theme.spacing(2),
      marginTop: theme.spacing(3),
      width: '6vw',
    },
    logoAlva: {
      height: '1.2vw',
      margin: theme.spacing(2),
      marginTop: theme.spacing(3),
      width: '5vw',
    },
    logoKalista: {
      height: '1.8vw',
      margin: theme.spacing(2),
      marginTop: theme.spacing(3),
      width: '6.4vw',
    },
    expandButton: {
      background: theme.palette.secondary.dark,
      color: colors.White,
    },
    iconClose: {
      transform: 'rotate(180deg)',
    },
    drawerHead: {
      ...theme.mixins.toolbar,
      alignItems: 'center',
      display: 'flex',
      justifyContent: ({ open }) => (open ? 'space-between' : 'center'),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(0, 1),
    },
    drawer: ({ open }) =>
      open
        ? {
            boxSizing: 'border-box',
            flexShrink: 0,
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }
        : {
            boxSizing: 'border-box',
            flexShrink: 0,
            whiteSpace: 'nowrap',
            width: closedDrawerWidth,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          },
    drawerPaper: ({ open }) =>
      open
        ? {
            width: drawerWidth,
            backgroundColor:
              MAPPING_CSS_DOMAIN[currentSubdomain] ||
              theme.palette.primary.main,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            overflowX: 'hidden',
          }
        : {
            width: closedDrawerWidth,
            backgroundColor:
              MAPPING_CSS_DOMAIN[currentSubdomain] ||
              theme.palette.primary.main,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
          },
    iconStyle: {
      color: colors.Concrete,
    },
  }
})

export default style
