import {
  ApolloLink,
  NextLink,
  Observable,
  Operation,
  WatchQueryFetchPolicy,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { cookies as zxAuthCookies } from '@xti/frontend-kit-auth/auth'

import { authRefresh } from '../auth/loginHelper'

interface AuthRefreshObservableParam {
  forward: NextLink
  headers: Record<string, any>
  operation: Operation
}

const authRefreshObservable = ({
  forward,
  headers,
  operation,
}: AuthRefreshObservableParam) =>
  new Observable((subscriber) => {
    authRefresh().then(
      (value) => {
        if (subscriber.closed) return
        subscriber.next(value)
        subscriber.complete()
      },
      (err) => subscriber.error(err)
    )
  }).flatMap((token) => {
    if (token) {
      operation.setContext({
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      })
      return forward(operation)
    }
    return Observable.of()
  })

export const expiredTokenMiddleware = onError(
  ({ graphQLErrors, operation, forward, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        const errMessage = `GraphQL error: ${message}`
        // TODO: Change to alert

        // eslint-disable-next-line no-console
        console.error(errMessage)
      })
    }

    if (networkError) {
      if ('statusCode' in networkError) {
        if (networkError.statusCode === 401) {
          const { headers } = operation.getContext()
          return authRefreshObservable({ forward, headers, operation })
        }
      }
    }

    return forward(operation)
  }
)

export const authMiddleWare = new ApolloLink((operation, forward) => {
  operation.setContext(() => {
    const { token } = zxAuthCookies.getToken()

    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  return forward(operation)
})

// Record of keys and the last fetched timestamp
// These are universal for all calls and hooks
const keys = new Map<string, number>()

export const ONE_MINUTE = 1000 * 60

export const getFetchPolicyForKey = (
  key: string,
  expirationMs: number
): WatchQueryFetchPolicy => {
  const lastFetchTimestamp = keys[key]

  const diffFromNow = lastFetchTimestamp
    ? Date.now() - lastFetchTimestamp
    : Number.MAX_SAFE_INTEGER
  let fetchPolicy: WatchQueryFetchPolicy = 'cache-first'

  // Is Expired?
  if (diffFromNow > expirationMs) {
    keys[key] = Date.now()
    fetchPolicy = 'network-only'
  }

  return fetchPolicy
}
