import React, { Suspense } from 'react'
import {
  Switch, Route, Redirect,
} from 'react-router-dom'

import Loading from '~/components/Loading'
import publicRoute from '~/routes/publicRoute'
import useAuth from '~/utils/auth/useAuth'


const PublicLayout = () => {

  const { isLoggedIn } = useAuth()

  return isLoggedIn
    ? null
    : (
      <>
        <Suspense fallback={<Loading loading />}>
          <Switch>
            {
              publicRoute.map(({ id, path, component: Component }) => 
              <Route key={id} path={path} component={Component} exact />
              )
            }
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </>
    )
}

export default PublicLayout
