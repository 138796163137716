import { useMemo } from 'react'

import routes from '~/routes'
import useAuth from '~/utils/auth/useAuth'

const useCustom = () => {
  const { isLoggedIn, userData } = useAuth()

  const defaultPath = useMemo(() => {
    if (!isLoggedIn || !userData) return

    const userPermissions: string[] = userData?.userAccess?.permission || []

    const defaultRoute = routes.find((f) =>
      userPermissions.includes(f.permission)
    )

    return defaultRoute.defaultPath
  }, [isLoggedIn, userData])
  return {
    data: {
      defaultPath,
    },
  }
}

export default useCustom
