import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@material-ui/core/CssBaseline'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import utc from 'dayjs/plugin/utc'
import { SnackbarProvider } from 'notistack'

import IndexPage from '~/pages/index'
import GlobalStyles from '~/styles/global'
import graphQLClient from '~/utils/apollo/client'
import { AuthProvider } from '~/utils/auth/AuthProvider'
import errorLogger from '~/utils/error-logger'
import { ServiceWorkerProvider } from '~/utils/service-worker'

import 'leaflet'
import 'leaflet-rotatedmarker'
import 'leaflet/dist/leaflet.css'

dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(duration)
dayjs.extend(utc)

// init error logger on production env
if (process.env.NODE_ENV === 'production') {
  errorLogger.init()
}

const render = () => {
  ReactDOM.render(
    <ServiceWorkerProvider>
      <AuthProvider>
        {/* <ThemeProvider theme={theme}> */}
        <GlobalStyles />
        <ApolloProvider client={graphQLClient}>
          <BrowserRouter>
            <SnackbarProvider>
              <CssBaseline />

              <IndexPage />
            </SnackbarProvider>
          </BrowserRouter>
        </ApolloProvider>
        {/* </ThemeProvider> */}
      </AuthProvider>
    </ServiceWorkerProvider>,
    document.getElementById('root')
  )
}

render()

if (module.hot) {
  module.hot.accept()
}
