import React, { memo } from 'react'

import {
  ListItemIcon,
  Box,
  ListItem,
  List,
  ListItemText,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import classnames from 'classnames'
import { ReactSVG } from 'react-svg'

import useScreenMobile from '~/utils/hooks/useScreenMobile'

import useCustom from './hooks'
import useStyles from './style'

interface Props {
  open: boolean
}

const Bottom = ({ open }: Props) => {
  const classes = useStyles({ open })
  const { profileName, handleDirectProfile, handleLogout } = useCustom()
  const isMobile = useScreenMobile()
  const theme = useTheme()

  return (
    <>
      <Box bgcolor={theme.palette.primary.main} mr={1}>
        <List>
          <ListItem
            className={classes.listItem}
            button
            disableRipple
            onClick={handleDirectProfile}
          >
            <ListItemIcon
              className={`${classes.listItemIcon} ${classes.listItemConfigIcon}`}
            >
              <ReactSVG src="/public/icons/menu-icon/profile.svg" />
            </ListItemIcon>
            <ListItemText
              className={classnames({ [classes.listItemText]: !isMobile })}
            >
              <div className={classes.itemText}>{profileName}</div>
            </ListItemText>
          </ListItem>
          <ListItem
            className={classes.listItem}
            onClick={handleLogout}
            button
            disableRipple
          >
            <ListItemIcon
              className={`${classes.listItemIcon} ${classes.listItemConfigIcon}`}
            >
              <ReactSVG src="/public/icons/menu-icon/logout.svg" />
            </ListItemIcon>
            <ListItemText
              className={classnames({ [classes.listItemText]: !isMobile })}
            >
              <div className={classes.itemText}>Logout</div>
            </ListItemText>
          </ListItem>
        </List>
        <Box
          width="100%"
          alignItems="center"
          display="flex"
          className={classes.footerTitle}
        >
          <Box hidden={!open} mr={2}>
            Powered by
          </Box>
          <Box>
            <ReactSVG src="/public/brands/logo-xapiens.svg" />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default memo(Bottom)
