function style() {
  return {
    wrapper: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }
}

export default style
