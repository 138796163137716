import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    offset: theme.mixins.toolbar,
    appBar: {
      background: `${theme.palette.primary.main} !important`,
    },
    toolbar: {
      paddingLeft: theme.spacing(6),
    },
    imgLogo: {
      width: 80,
      height: '100%',
    },
  })
)
