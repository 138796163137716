import React from 'react'

import Visibility from '@material-ui/icons/Visibility'

import { DevicesFilterType, QcFilterType } from '~/gqlcodegen/fms/types'

import { idleIcon, offlineIcon, onlineIcon } from './iconMap'

import { DOMAINS } from '.'

export const AUTOHIDE_DURATION_SNACKBAR = 5000

export const UNAUTHORIZED = 'Unauthorized'

export const LOCAL_STORAGE_KEY_USER = 'USER_DATA'

export const PAGINATION_RESULT_DEFAULT = {
  current: 1,
  totalPage: 1,
}

export const MAPPING_COMMANDS_TYPE = {
  SUPERSOCO_LOCK: 'SUPERSOCO_LOCK',
  SUPERSOCO_UNLOCK: 'SUPERSOCO_UNLOCK',
  SUPERSOCO_POWERON: 'SUPERSOCO_POWERON',
  SUPERSOCO_OPENSEAT: 'SUPERSOCO_OPENSEAT',
  CAN_ON: 'setdigout 1?',
  CAN_OFF: 'setdigout 0?',
}

export const SNACKBAR_OPTIONS = {
  DURATION: 10000,
  SIZE: 'md',
}

export const RESPONSE_MESSAGE = {
  CREATED_OK: 'Data Saved Successfully',
  UPDATED_OK: 'Data Updated Successfully',
  DELETED_OK: 'Data Deleted Successfully',
  ASSIGN_OK: 'Data Assigned Successfully',
  UNASSIGN_OK: 'Data Unassigned Successfully',
  DEFAULT: 'Something went wrong',
}

export const PAGINATION_OPTIONS = [
  {
    value: 5,
    label: '5/page',
  },
  {
    value: 10,
    label: '10/page',
  },
  {
    value: 15,
    label: '15/page',
  },
]

export const VEHICLE_STATUS = {
  STOP: 'stop',
  MOVING: 'moving',
  IDLE: 'idling',
  NA: 'n/a',
}

export const VEHICLE_STATUS_LABEL_MAPPING = {
  [VEHICLE_STATUS.IDLE]: 'Idle',
  [VEHICLE_STATUS.MOVING]: 'Moving',
  [VEHICLE_STATUS.STOP]: 'Stop',
  [VEHICLE_STATUS.NA]: 'N/A',
}

export const DEVICES_FILTER_BY = {
  ALL: DevicesFilterType.None,
  ASSIGNED: DevicesFilterType.Assigned,
  UNASSIGNED: DevicesFilterType.Unassigned,
}

export const QC_FILTER = {
  ALL: QcFilterType.None,
  PASS: QcFilterType.Pass,
  FAIL: QcFilterType.Fail,
  NOT_TESTED: 'NOT_TESTED',
}

export const QC_STATUS_LABEL = {
  [QC_FILTER.NOT_TESTED]: 'Not Tested',
  [QC_FILTER.PASS]: 'QC Passed',
  [QC_FILTER.FAIL]: 'QC Failed',
}

export const QC_STATUS_COLOR = {
  [QC_STATUS_LABEL[QC_FILTER.FAIL]]: 'danger',
  [QC_STATUS_LABEL[QC_FILTER.PASS]]: 'success',
  [QC_STATUS_LABEL[QC_FILTER.NOT_TESTED]]: 'default',
}

export const ASSIGNED_STATUS_COLOR = {
  [DevicesFilterType.Assigned]: 'success',
  [DevicesFilterType.Unassigned]: 'danger',
}

export const QC_ICON = {
  [QC_STATUS_LABEL[QC_FILTER.FAIL]]: '',
  [QC_STATUS_LABEL[QC_FILTER.PASS]]: <Visibility className="responsive-icon" />,
  [QC_STATUS_LABEL[QC_FILTER.NOT_TESTED]]: '',
}

export const indonesiaPolygonCoordinate = [
  [
    [94.7717121989, -11.2085667762],
    [141.0200344026, -11.2085667762],
    [141.0200344026, 6.2744498481],
    [94.7717121989, 6.2744498481],
    [94.7717121989, -11.2085667762],
  ],
]

export const manufacturingArea = [
  [
    [107.163, -6.3405],
    [107.163, -6.3605],
    [107.183, -6.3605],
    [107.183, -6.3405],
    [107.163, -6.3405],
  ],
]

export const areaAEC = [
  [
    [106.799, -6.213],
    [106.799, -6.233],
    [106.819, -6.233],
    [106.819, -6.213],
    [106.799, -6.213],
  ],
]

export const ROW_SIZE = 70

export const PERMISSIONS = {
  FMS_READ_DASHBOARD: 'FMS_READ_DASHBOARD',
  FMS_ON_OFF_COMMAND: 'FMS_ON_OFF_COMMAND',
  FMS_READ_ALERT: 'FMS_READ_ALERT',
  FMS_READ_DEVICE: 'FMS_READ_DEVICE',
  FMS_READ_DRIVER: 'FMS_READ_DRIVER',
  FMS_READ_GEOFENCE: 'FMS_READ_GEOFENCE',
  FMS_READ_IOT_CHECK_AFTER_SALES: 'FMS_READ_IOT_CHECK_AFTER_SALES',
  FMS_READ_IOT: 'FMS_READ_IOT',
  FMS_READ_LIVETRACK: 'FMS_READ_LIVETRACK',
  FMS_READ_MANUFACTURE: 'FMS_READ_MANUFACTURE',
  FMS_READ_PREDELIVERY_ASSETS: 'FMS_READ_PREDELIVERY_ASSETS',
  FMS_READ_PREDELIVERY_TRACKING: 'FMS_READ_PREDELIVERY_TRACKING',
  FMS_READ_REPORT: 'FMS_READ_REPORT',
  FMS_READ_REPORT_GROUP: 'FMS_READ_REPORT_GROUP',
  FMS_READ_COMMAND_ACCESS_HISTORY: 'FMS_READ_COMMAND_ACCESS_HISTORY',
  FMS_READ_SUBSCRIPTIONS: 'FMS_READ_SUBSCRIPTIONS',
  FMS_READ_TEST_RECORD: 'FMS_READ_TEST_RECORD',
  FMS_READ_VEHICLE: 'FMS_READ_VEHICLE',
  FMS_READ_ROUTES: 'FMS_READ_ROUTES',
  FMS_ASSET_PLUS: 'FMS_ASSET_PLUS',
  FMS_TRACKING_PLUS: 'FMS_TRACKING_PLUS',
  FMS_SHOW_MAP: 'FMS_SHOW_MAP',
  FMS_WRITE_COMMAND: 'FMS_WRITE_COMMAND',
  FMS_WRITE_DEVICE: 'FMS_WRITE_DEVICE',
  FMS_WRITE_DRIVER: 'FMS_WRITE_DRIVER',
  FMS_WRITE_GEOFENCE: 'FMS_WRITE_GEOFENCE',
  FMS_WRITE_IOT: 'FMS_WRITE_IOT',
  FMS_WRITE_MANUFACTURE: 'FMS_WRITE_MANUFACTURE',
  FMS_WRITE_SUBSCRIPTIONS: 'FMS_WRITE_SUBSCRIPTIONS',
  FMS_WRITE_TEST_RECORD: 'FMS_WRITE_TEST_RECORD',
  FMS_WRITE_VEHICLE: 'FMS_WRITE_VEHICLE',
  READ_ORGANIZATION: 'READ_ORGANIZATION', // GROUP
  FMS_PHR_HISTORICAL: 'FMS_PHR_HISTORICAL',
}

export enum DateTypeOptions {
  LastOneHour = 'lastOneHour',
  Today = 'today',
  Yesterday = 'yesterday',
  DateRange = 'dateRange',
}

export const DATE_TYPE_OPTIONS = [
  {
    label: 'Last 1 Hour',
    value: DateTypeOptions.LastOneHour,
  },
  {
    label: 'Today',
    value: DateTypeOptions.Today,
  },
  {
    label: 'Yesterday',
    value: DateTypeOptions.Yesterday,
  },
  {
    label: 'Date Range',
    value: DateTypeOptions.DateRange,
  },
]

export const API_DATE_FORMAT = 'YYYY-MM-DD'

export const API_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const QC_OPTIONS = [
  {
    label: 'All',
    value: QC_FILTER.ALL,
  },
  {
    label: 'Passed',
    value: QC_FILTER.PASS,
  },
  {
    label: 'Failed',
    value: QC_FILTER.FAIL,
  },
]

export const STATUS_OPTIONS = [
  {
    label: 'All',
    value: DEVICES_FILTER_BY.ALL,
  },
  {
    label: 'Assigned',
    value: DEVICES_FILTER_BY.ASSIGNED,
  },
  {
    label: 'Unassigned',
    value: DEVICES_FILTER_BY.UNASSIGNED,
  },
]

export const statusVariantMapping = {
  completed: 'info',
  pending: 'pending',
  'no data available': 'default',
  failed: 'danger',
}

// Status icon mapping
export const iconStatusMapping = {
  [VEHICLE_STATUS.IDLE]: idleIcon,
  [VEHICLE_STATUS.MOVING]: onlineIcon,
  [VEHICLE_STATUS.STOP]: offlineIcon,
  [VEHICLE_STATUS.NA]: offlineIcon,
}

// Mapping vehicle status color variant
export const variantStatusMapping = {
  [VEHICLE_STATUS.IDLE]: 'warning',
  [VEHICLE_STATUS.MOVING]: 'success',
  [VEHICLE_STATUS.STOP]: 'danger',
  [VEHICLE_STATUS.NA]: 'default',
}
export const DEFAULT_ZOOM_RADIUS = 10

export const FLEET_MODEL = {
  ALVA2: 'ALVA2',
}

export const DEVICE_MODEL = {
  CAN: 'TFT100 CAN',
}

export const FLEET_MODEL_DEVICE = {
  'TFT100 RS485': 'ALVA ONE',
  'TFT100 CAN': 'ALVA CERVO',
}

export const centerJakarta = [-6.22678, 106.798186]

export const logoLoginMapping = {
  [DOMAINS.FLEETO_ALVA_DOMAIN as string]: '/public/brands/alva-dark.svg',
  [DOMAINS.FLEETO_MAIN_DOMAIN as string]: '/public/brands/logo-fleeto-v4.svg',
}

export const REPLAY_CONTROL = {
  PAUSE: 'PAUSE',
  PLAY: 'PLAY',
  SKIP_NEXT: 'SKIP_NEXT',
  SKIP_PREVIOUS: 'SKIP_PREVIOUS',
  SPEED_CONTROL: 'SPEED_CONTROL',
  STOP: 'STOP',
}
