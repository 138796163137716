import { Icon } from 'leaflet'

export const onlineIcon = new Icon({
  iconUrl: '/public/icons/vehicleMark.svg',
  iconSize: [41, 41],
  iconAnchor: [20, 0],
})
export const offlineIcon = new Icon({
  iconUrl: '/public/icons/vehicleMarkOffline.svg',
  iconSize: [20, 20],
  iconAnchor: [7, 0],
})
export const idleIcon = new Icon({
  iconUrl: '/public/icons/vehicleMarkIdling.svg',
  iconSize: [41, 41],
  iconAnchor: [20, 0],
})
export const firstTripIcon = new Icon({
  iconUrl: '/public/icons/vehicleMarkFirstTrip.svg',
  iconSize: [20, 20],
  iconAnchor: [7, 0],
})
export const lastTripIcon = new Icon({
  iconUrl: '/public/icons/vehicleMarkLastTrip.svg',
  iconSize: [41, 41],
  iconAnchor: [20, 0],
})
export const inZoneIcon = new Icon({
  iconUrl: '/public/icons/in-zone.svg',
  iconSize: [41, 41],
  iconAnchor: [20, 20],
})
export const outZoneIcon = new Icon({
  iconUrl: '/public/icons/out-zone.svg',
  iconSize: [41, 41],
  iconAnchor: [20, 20],
})

export const maintenanceIcon = new Icon({
  iconUrl: '/public/icons/icon-geolocation/maintenance.svg',
  iconSize: [30, 30],
  iconAnchor: [20, 20],
})

export const distributionIcon = new Icon({
  iconUrl: '/public/icons/icon-geolocation/distribution.svg',
  iconSize: [30, 30],
  iconAnchor: [20, 20],
})
export const officeIcon = new Icon({
  iconUrl: '/public/icons/icon-geolocation/office.svg',
  iconSize: [30, 30],
  iconAnchor: [20, 20],
})
export const parkingIcon = new Icon({
  iconUrl: '/public/icons/icon-geolocation/parking.svg',
  iconSize: [30, 30],
  iconAnchor: [20, 20],
})
export const warehouseIcon = new Icon({
  iconUrl: '/public/icons/icon-geolocation/warehouse.svg',
  iconSize: [30, 30],
  iconAnchor: [20, 20],
})

export const busIcon = new Icon({
  iconUrl: '/public/icons/bus-icon.svg',
  iconSize: [41, 41],
  iconAnchor: [20, 40],
})
