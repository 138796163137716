import React, { memo } from 'react'

import { Box, CircularProgress } from '@material-ui/core'

interface Props {
  children?: React.ReactNode
  height?: string
  loading: boolean
  loadingComponent?: React.ReactNode
  py?: number | string
}

const Loading = ({
  children,
  height = null,
  loading,
  loadingComponent = null,
  py,
}: Props) => {
  if (loading) {
    return (
      <Box
        data-testid="loading-container"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={height}
        py={py}
      >
        {loadingComponent || (
          <CircularProgress data-testid="circular" color="secondary" />
        )}
      </Box>
    )
  }

  return <>{children}</>
}

export default memo(Loading)
