import { createStyles, makeStyles } from '@material-ui/core/styles'

import { colors, fontSize } from '~/styles/theme'

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      '*': {
        fontFamily: 'Inter, sans-serif',
      },
      '#root': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      'html, body': {
        margin: 0,
        height: '100%',
        overflowX: 'hidden',
        '@media (max-width: 1680px)': {
          fontSize: fontSize[14],
        },
      },
      '*::-webkit-scrollbar': {
        width: 6,
        height: 10,
        border: `1px solid ${colors.Solitude}`,
        marginRight: theme.spacing(3),
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 16,
        borderColor: 'none',
        cursor: 'pointer',
      },
      '.autocomplete-outlined': {
        padding: 3,
      },
      '.main-textfield': {
        backgroundColor: colors.White,
        width: '100%',
        borderRadius: 4,
        borderWidth: 0.6,

        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingTop: theme.spacing(0),
          paddingBottom: theme.spacing(0),
        },
      },
      '.btn-rounded': {
        borderRadius: 28,
        padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
      },
      '.buttonModal': {
        width: 180,
        fontSize: theme.fontSize[16],
      },
      '.boxRounded': {
        borderRadius: '5px',
        border: `1px solid ${colors.Mercury}`,
      },
      '.tableBox': {
        backgroundColor: colors.White,
        maxWidth: '100%',
        borderRadius: '4px',
        boxShadow: '1px 2px 4px rgba(193, 193, 193, 0.18)',
        height: 'auto',
      },
      '.inputSelect': {
        backgroundColor: colors.White,
        width: '100%',
        padding: 0,
        borderRadius: 5,
        borderWidth: 0.6,
        borderColor: colors.GreyArea,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: colors.GreyArea,
          },
        },
        '& .MuiOutlinedInput-input': {
          padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
          color: colors.GrayChateau,
        },
      },
      '.btn-grey': {
        color: `${colors.blue[80]} !important`,
        padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
        backgroundColor: `${colors.AthensGray} !important`,
      },
      '.btn-text-small': {
        fontSize: theme.fontSize[14],
        fontWeight: theme.typography.fontWeightRegular,
      },
      '.btn-text': {
        textDecoration: 'underline !important',
      },
      '.snack-icon': {
        fontSize: fontSize[24],
        color: colors.White,
      },
      '.btn-back': {
        color: `${colors.blue[80]} !important`,
        padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
        backgroundColor: `${colors.AthensGray} !important`,
      },
      '.responsive-icon': {
        [theme.breakpoints.down('lg')]: {
          fontSize: `${fontSize[14]} !important`,
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: `${fontSize[20]} !important`,
        },
      },
      '.map-container': {
        height: '100%',
        width: '100%',
        flexGrow: 1,
        '& .leaflet-tooltip': {
          backgroundColor: 'transparent',
          fontSize: theme.fontSize[16],
          fontWeight: theme.typography.fontWeightBold,
          border: 'none',
          boxShadow: 'none',
        },
        '& .leaflet-tooltip-top:before': {
          borderTopColor: 'transparent',
        },
        '& .leaflet-popup-content-wrapper': {
          minWidth: '12.4vw',
          minHeight: '16.5vh',
        },
        '& .leaflet-top': {
          bottom: 0,
        },
        '& .leaflet-top .leaflet-control-zoom': {
          top: 0,
          bottom: 0,
          transform: 'translateY(-15%)',
        },
      },
      '.marker-cluster-base': {
        fontSize: fontSize[24],
        color: colors.Black,
        fontWeight: theme.typography.fontWeightBold,
      },
      '.marker-cluster-small': {
        backgroundColor: 'rgba(218, 94, 94, 0.6)',
      },
      '.marker-cluster-small div': {
        backgroundColor: 'rgba(226, 36, 36, 0.6)',
      },
      ' .marker-cluster-medium': {
        backgroundColor: 'rgba(241, 211, 87, 0.6)',
      },
      '.marker-cluster-medium div': {
        backgroundColor: 'rgba(240, 194, 12, 0.6)',
      },
      '.marker-cluster-large': {
        backgroundColor: 'rgba(253, 156, 115, 0.6)',
      },
      '.marker-cluster-large div': {
        backgroundColor: 'rgba(241, 128, 23, 0.6)',
      },
      '.hide-on-mobile': {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
          'content-visibility': 'none',
        },
      },
      '.mobile-container': {
        marginTop: 69,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },
      '.img-responsive': {
        height: 'auto',
        width: 'auto',
        [theme.breakpoints.down('xs')]: {
          height: 46,
        },
      },
      '.no-underline': {
        textDecoration: 'none',
        color: 'inherit',
      },
      '.tooltip-leaflet': {
        backgroundColor: 'white',
        color: 'black',
        padding: '5px',
        borderRadius: '3px',
        fontSize: 10,
        fontWeight: 400,
      },
      '.popup-marker': {
        paddingBottom: 30,
        '& .leaflet-popup-content-wrapper': {
          minHeight: 0,
          minWidth: 0,
        },
      },
    },
  })
)

const GlobalStyles = () => {
  useStyles()

  return null
}

export default GlobalStyles
