import { makeStyles, Theme } from '@material-ui/core/styles'

import { colors } from '~/styles/theme'

interface IProps {
  open: boolean
}

const style = makeStyles<Theme, IProps>((theme) => ({
  logo: {
    color: colors.White,
    paddingTop: 1,
  },
  listItem: {
    minHeight: 48,
    justifyContent: ({ open }) => (open ? 'initial' : 'center'),
    padding: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: colors.White,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '6px',
      color: colors.White,
      justifyContent: 'center',
    },
  },
  listItemIcon: {
    minWidth: 0,
    marginRight: 0,
    justifyContent: 'center',
    color: 'inherit',
  },
  listItemConfigIcon: {
    marginRight: ({ open }) => (open ? theme.spacing(3) : 0),
  },
  listItemText: {
    display: ({ open }) => (open ? 'block' : 'none'),
  },
  footerTitle: ({ open }) => {
    const base = {
      color: colors.White,
      fontWeight: theme.typography.fontWeightLight,
      marginBottom: theme.spacing(5),
      paddingLeft: theme.spacing(3),
    }

    if (open) {
      return {
        ...base,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }
    }

    return base
  },
  itemText: {
    fontSize: theme.fontSize[16],
  },
  itemDesc: {
    fontSize: theme.fontSize[12],
  },
}))

export default style
