import React from 'react'
import { memo } from 'react'

import { Box, Drawer, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { colors } from '~/styles/colors'
import { fontSize } from '~/styles/theme'

import NavbarMobile from '../../NavbarMobile'

import useStyle from './style'

interface Props {
  children: React.ReactNode
  handleClickToggle: () => void
  open: boolean
}

const MobileSidebar = ({ children, open, handleClickToggle }: Props) => {
  const classes = useStyle()
  return (
    <>
      <NavbarMobile handleClickExpandMenu={handleClickToggle} />
      <Drawer
        id="main-menu-drawer-mobile"
        anchor="left"
        open={open}
        onClose={handleClickToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box display="flex" alignItems="center">
          <Box
            fontWeight="bold"
            color={colors.White}
            ml={3}
            flexGrow={1}
            fontSize={fontSize[18]}
          >
            Menu
          </Box>
          <IconButton aria-label="burger-menu-btn" onClick={handleClickToggle}>
            <Box fontSize={fontSize[40]}>
              <Close htmlColor={colors.White} fontSize="inherit" />
            </Box>
          </IconButton>
        </Box>
        <Box>{children}</Box>
      </Drawer>
    </>
  )
}

export default memo(MobileSidebar)
